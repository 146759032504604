.react-datepicker-wrapper {
  width: 100%;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.fr-view {
  a {
    text-decoration: underline;
    color: #0000EE;
  }
}