.address-wrapper {
    position: relative; }

.address-wrapper input {
    width: 100%;
    min-width: 200px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    transition-property: var(--nucleus-transition-property-common);
    transition-duration: var(--nucleus-transition-duration-normal);
    font-size: var(--nucleus-fontSizes-md);
    -webkit-padding-start: var(--nucleus-space-4);
    padding-inline-start: var(--nucleus-space-4);
    -webkit-padding-end: var(--nucleus-space-4);
    padding-inline-end: var(--nucleus-space-4);
    height: var(--nucleus-sizes-10);
    border-radius: 4px;
    border: 1px solid;
    border-color: var(--nucleus-colors-gray-300);
    background: inherit;
    focus-border-color: primary;
}

.location-result {
    padding-top: 15px;
    font-weight: 600; }
.location-result code {
    background: #dfeef7;
    padding: 3px 5px;
    border-radius: 2px;
    border: 1px solid #6eb3ff;
    color: #037bff; }

.address-result-container {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 9999;
    background: white;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 1px solid #eaeaea;
    border-top: 0px; }
.address-result-container .address-result {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    font-size: 0.9rem; }
.address-result-container .address-result i {
    margin-right: 15px; }
.address-result-container .dropdown-footer {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #f4f5f7;
    position: relative;
    text-align: right; }
.address-result-container .dropdown-footer img {
    width: 130px;
    float: right;
    margin-right: 10px; }
